<template>
  <section id="stats">
    <!-- <v-parallax
          :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
          src="https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
      >-->
    <v-parallax src="@/assets/images/highway_lights.jpg" :height="$vuetify.breakpoint.smAndDown ? 600 : 400">
      <v-container fill-height>
        <!-- <v-row class="mx-auto">
          <v-col v-for="({ title, value }, i) of stats" :key="i" cols="12" md="3">
            <div class="text-center">
              <div class="display-3 font-weight-black mb-4 text-shadow" v-text="value"></div>
              <div class="title font-weight-regular text-uppercase text-shadow" v-text="title"></div>
            </div>
          </v-col>
        </v-row> -->
      </v-container>
    </v-parallax>
  </section>
</template>

<script>
export default {
  name: 'Stats',
  data: () => ({
    stats: [
      {
        title: 'Customers',
        value: '2.9k'
      },
      {
        title: 'Title Transfers',
        value: '3.3k'
      },
      {
        title: 'Hours Saved',
        value: '1k+'
      },
      {
        title: 'Repeat Customers',
        value: '75%'
      }
    ]
  })
};
</script>
